
 // Body
 $body-bg: #fff;

 // Borders
 $laravel-border-color: darken($body-bg, 10%);
 $list-group-border-color: $laravel-border-color;

 $card-border-color: $laravel-border-color;

 // Brands
 $primary: #242422;
 $info: #8eb4cb;
 $success: #2ab27b;
 $warning: #cbb956;
 $danger: #bf5329;

 // Typography
 //$font-family-sans-serif: "Raleway", sans-serif;
 $font-size-base: 1.2rem;//.875rem; // 14px
 $line-height-base: 1.4;
 $text-color: #636b6f;

 // Inputs
 $input-border-color: lighten($text-color, 40%);
 $input-placeholder-color: lighten($text-color, 30%);


 $h1-font-size: 1.8rem !default;
 $h2-font-size: 1.6rem !default;
 $h3-font-size: 1.4rem !default;
 $h4-font-size: 1.2rem !default;
 $h5-font-size: 1.1rem !default;