// Fonts
//@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
@import url("https://fonts.googleapis.com/css?family=Palanquin&display=swap");

$font-family-sans-serif: "Palanquin", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

// Variables
@import "variables";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Font Awesome icons
@import "~font-awesome/css/font-awesome.css";
@import "~jquery-ui/themes/base/all.css";
@import "~lightbox2/dist/css/lightbox";

.form-check-input {
    margin-left: 1;
}

.img-thumbnail {
    padding: 0;
}

body {
    margin: 0;
}

.current {
    border-bottom: 3px solid white;
}

.form-check {
    padding-left: 0;

    .form-check-label {
        padding-left: 1.25rem;
    }
}

.drop {
    filter: drop-shadow(0 0 5px black);
}

.row.favourite {
    background-color: whitesmoke;
}

#header {
    position: fixed;
    top: 0;
    z-index: 20;
    height: 100px;
    width: 100%;
    transition: height 0.5s;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    color: white;

    &.smaller {
        height: 60px;
    }

    a {
        color: white;
        font-size: 1.1 * $font-size-base;
        text-shadow: 2px 2px #1f1f1f;

        &:hover {
            text-decoration: none;
        }
    }

    #nav {
        width: 250px;
    }
}

.no-webp #header {
    background-color: black;
    // background-image: url("/images/winter.jpg");
    // background-size: cover;
}

.webp #header {
    background-color: black;
    // background-image: url("/images/winter.webp");
    // background-size: cover;
}

#inner_wrapper {
    padding-top: 100px;

    &.smaller {
        padding-top: 60px;
    }

    .rest-info {
        font-size: 0.8 * $font-size-base;
    }

    .menu {
        p {
            margin-bottom: 0.3rem;
        }
        ul {
            margin: 0.75em 0;
            padding: 0 1em;
            list-style: none;
        }
        li:before {
            font-family: "FontAwesome";
            content: "\f105";
            border-color: transparent #111;
            display: block;
            height: 0;
            width: 0;
            left: -0.9em;
            top: 0.1em;
            position: relative;
        }
        .category-header,
        .info-header {
            font-size: 1.3rem;
            font-weight: 600;
        }
        .rest_logo {
            text-align: center;
        }
        img {
            max-width: 230px;
            max-height:180px;
        }
    }
}

@media (min-width: 768px) {
    h4 {
        font-size: 1.2 * $font-size-base;
    }

    #header {
        a {
            font-size: 1.3 * $font-size-base;
        }

        height: 130px;

        &.smaller {
            height: 130px;
        }

        #nav {
            width: 350px;
        }

        .nav-items {
            padding: 0;
        }

        #week {
            font-size: 1.3 * $font-size-base;
        }
    }

    #inner_wrapper {
        padding-top: 130px;

        &.smaller {
            padding-top: 130px;
        }
        &.menu {
            img {
                max-width: 280px;
                max-height:180px;
            }
        }
    }
}

#snowflakeContainer {
    position: absolute;
    left: 0px;
    top: 0px;
}

.snowflake {
    padding-left: 15px;
    font-family: Cambria, Georgia, serif;
    font-size: 14px;
    line-height: 24px;
    position: fixed;
    color: #ffffff;
    user-select: none;
    z-index: 1000;

    &:hover {
        cursor: default;
    }
}
